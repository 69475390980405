@mixin flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list-item-wrapper {
  //@include flex-between;
  width: 100%;
}

.delete-date-container {
  @include flex-between;
  #delete {
    margin-right: 10px;
    cursor: pointer;
  }
}