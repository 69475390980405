@import '~antd/dist/antd.css';

@font-face {
    font-family: IRanSans;
    font-style: normal;
    font-weight: 300;
    src: local('IRanSans'),
    url(../public/assets/fonts/IRanSans/IRANSansWeb\(FaNum\).woff) format('woff'),
    url(../public/assets/fonts/IRanSans/IRANSansWeb\(FaNum\).woff2) format('woff2'),
    url(../public/assets/fonts/IRanSans/IRANSansWeb\(FaNum\).eot) format('eot'),
    url(../public/assets/fonts/IRanSans/IRANSansWeb\(FaNum\).ttf) format('ttf');
    font-display: swap;
}

body {
    font-family: 'IRANSans';
}

/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #FFD885 #fff;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-track {
    background: #fff;
}

*::-webkit-scrollbar-thumb {
    background-color: #FFD885;
    border-radius: 20px;
    border: 3px solid #fff;
}

.delete-icon {
    color: #FFD885;
    font-size: 18px;
    font-weight: bold;
}

.ant-input {
    border-radius: 8px;
}

.ant-select {
    width: 100%;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
}

.ant-select-selection-item {
    font-size: 14px !important;
}

.ant-modal-mask {
    background-color: rgba(58, 58, 58, .85);
}

.ant-modal-content {
    border-radius: 10px !important;
}

.ant-btn-primary {
    background-color: #32B8C5 ;
    border-color: transparent !important;
}

.ant-btn-background-ghost.ant-btn-primary {
  background-color: transparent !important;
  border-color: #32B8C5 !important;
  color: #32B8C5 !important;
}

/*.ant-btn-primary:hover {*/
/*  background-color: #7DE3ED !important;*/
/*  border-color: #7DE3ED !important;*/
/*}*/

input[type='radio'] {
    accent-color: #32B8C5 !important;
}

#scrollableDiv {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: none !important;
    margin-top: 2rem !important;
}

.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:last-child, .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector, .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input, .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input, .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-radius: 0 8px 8px 0 !important;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:first-child, .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector, .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input, .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input{
  border-radius: 8px 0 0 8px !important;
}

.ant-collapse-borderless {
    background-color: transparent !important;
}

.ant-collapse-borderless > .ant-collapse-item {
    border: none !important;
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
    border: 1px solid #32B8C5;
    border-radius: 8px;
    padding-top: 1rem;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
    font-weight: 600;
    font-size: 18px;
    color: #696969;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    flex-direction: row-reverse;
    align-items: center;
}

.ant-checkbox-wrapper {
    color: #7E7E7E !important;
    font-size: 16px;
    font-weight: 500;
}

.ant-select-selector {
    direction:rtl !important;
}